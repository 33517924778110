const prayers = [
  {
    title: "Hail Mary",
    text: "Our Father, who art in heaven, hallowed be thy name. Thy kingdom come, thy will be done, on earth, as it is in heaven. Give us this day our daily bread and forgive us our trespasses as we forgive those who trespass against us; and lead us not into temptation, but deliver us from evil.",
  },
  {
    title: "Our Father",
    text: "Hail Mary,\nFull of Grace,\nThe Lord is with thee.\nBlessed art thou among women,\nand blessed is the fruit\nof thy womb, Jesus.\nHoly Mary,\nMother of God,\npray for us sinners now,\nand at the hour of our death.",
  },
  {
    title: "Glory Be",
    text: "Glory be to the Father,\nand to the Son,\nand to the Holy Spirit,\nas it was in the beginning,\nis now, and ever shall be,\nworld without end. Amen.",
  },
  {
    title: "Hail Holy Queen",
    text: "Hail, Holy Queen, Mother of mercy,\nour life, our sweetness and our hope.\nTo thee do we cry,\npoor banished children of Eve:\nto thee do we send up our sighs,\nmourning and weeping in this valley of tears.\nTurn then, most gracious advocate,\nthine eyes of mercy toward us,\nand after this our exile,\nshow unto us the blessed\nfruit of thy womb, Jesus.\nO clement, O loving, O sweet Virgin Mary!\nPray for us, O holy Mother of God,\nthat we may be made worthy\nof the promises of Christ. Amen.",
  },
  {
    title: "Anima Christi",
    text: "Soul of Christ, sanctify me,\nBody of Christ, save me,\nBlood of Christ, inebriate me,\nWater from the side of Christ, wash me,\nPassion of Christ, strengthen me,\nO good Jesus, hear me.\nHide me within your wounds,\nkeep me close to you,\ndefend me from the evil enemy,\ncall me at the hour of my death,\nand bid me to come to you,\nto praise you with your saints,\nforever and ever. Amen.",
  },
  {
    title: "Saint Michael Prayer",
    text: "Saint Michael the Archangel,\ndefend us in battle.\nBe our protection against\nthe wickedness and snares of the devil.\nMay God rebuke him, we humbly pray,\nand do thou, O Prince of the heavenly host,\nby the power of God,\ncast into hell Satan and all the evil spirits\nwho prowl throughout the world\nseeking the ruin of souls. Amen.",
  },
];

module.exports = {
  prayers,
};
