const rosaryPrayers = [
  {
    bead: [2],
    title: "Pray the Apostles' Creed",
    prayer:
      "I believe in God, the Father almighty,<br></br>Creator of heaven and earth,<br></br>and in Jesus Christ, His only Son, our Lord,<br></br>who was conceived by the Holy Spirit,<br></br>born of the Virgin Mary,<br></br>suffered under Pontius Pilate,<br></br>was crucified, died and was buried;<br></br>He descended into hell;<br></br>on the third day He rose again from the dead;<br></br>He ascended into heaven,<br></br>and is seated at the right hand of God the Father almighty;<br></br>from there He will come to judge the living and the dead.<br></br>I believe in the Holy Spirit,<br></br>the holy catholic Church,<br></br>the communion of saints,<br></br>the forgiveness of sins,<br></br>the resurrection of the body, and life everlasting.<br></br>Amen.",
  },
  {
    bead: [3, 8, 21, 34, 47, 60],
    title: "Our Father",
    prayer:
      "Our Father, who art in heaven, hallowed be thy name; thy kingdom come; thy will be done on earth as it is in heaven. Give us this day our daily bread; and forgive us our trespasses as we forgive those who trespass against us; and lead us not into temptation, but deliver us from evil. Amen.",
  },
  {
    bead: [4, 5, 6, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
    title: "Hail Mary",
    prayer:
      "Hail Mary, full of grace, the Lord is with you; blessed are you among women, and blessed is the fruit of your womb, Jesus. Holy Mary, Mother of God, pray for us sinners now and at the hour of our death. Amen.",
  },
  {
    bead: [7, 19, 32, 45, 58, 71],
    title: "Glory Be",
    prayer:
      "Glory be to the Father, the Son, and the Holy Spirit; as it was in the beginning, is now, and ever shall be, world without end. Amen.",
  },
  {
    bead: [20, 33, 46, 59, 72],
    title: "O my Jesus",
    decade: "End First Decade",
    prayer:
      "O my Jesus, forgive us our sins, save us from the fires of hell; lead all souls to Heaven, especially those who have most need of your mercy. Amen",
  },
  {
    bead: [22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
    title: "Hail Mary",
    prayer:
      "Hail Mary, full of grace, the Lord is with you; blessed are you among women, and blessed is the fruit of your womb, Jesus. Holy Mary, Mother of God, pray for us sinners now and at the hour of our death. Amen.",
  },
  {
    bead: [35, 36, 37, 38, 39, 40, 41, 42, 43, 44],
    title: "Hail Mary",
    prayer:
      "Hail Mary, full of grace, the Lord is with you; blessed are you among women, and blessed is the fruit of your womb, Jesus. Holy Mary, Mother of God, pray for us sinners now and at the hour of our death. Amen.",
  },
  {
    bead: [48, 49, 50, 51, 52, 53, 54, 55, 56, 57],
    title: "Hail Mary",
    prayer:
      "Hail Mary, full of grace, the Lord is with you; blessed are you among women, and blessed is the fruit of your womb, Jesus. Holy Mary, Mother of God, pray for us sinners now and at the hour of our death. Amen.",
  },
  {
    bead: [61, 62, 63, 64, 65, 66, 67, 68, 69, 70],
    title: "Hail Mary",
    prayer:
      "Hail Mary, full of grace, the Lord is with you; blessed are you among women, and blessed is the fruit of your womb, Jesus. Holy Mary, Mother of God, pray for us sinners now and at the hour of our death. Amen.",
  },
  {
    bead: [73],
    title: "Hail Holy Queen",
    prayer:
      "Hail, holy Queen, mother of mercy, our life, our sweetness, and our hope. To you we cry, poor banished children of Eve; to you we send up our sighs, mourning and weeping in this valley of tears.<br></br> Turn, then, most gracious advocate, your eyes of mercy toward us; and after this, our exile, show unto us the blessed fruit of your womb, Jesus. O clement, O loving, O sweet Virgin Mary.<br></br> Pray for us, O holy Mother of God. That we may be made worthy of the promises of Christ",
  },
  {
    bead: [74],
    title: "Closing Prayer",
    prayer:
      "O God, whose only-begotten Son, by His life, death and resurrection, has purchased for us the rewards of eternal life; grant, we beseech Thee, that, meditating upon these mysteries of the Most Holy Rosary of the Blessed Virgin Mary, we may imitate what they contain and obtain what they promise, through the same Christ our Lord. Amen. ",
  },
];

export default rosaryPrayers;
