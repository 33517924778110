import React from "react";

const LoadingComp = () => {
  return (
    <>
      <img src="/assets/cross.svg" alt="cross svg" className="cross_svg" />
      <p className="bible_text_container_prayer">
        "In the name of the Father, and of the Son, and of the Holy Spirit"
      </p>
    </>
  );
};

export default LoadingComp;
