import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "./Pages/HomePage";
import Read from "./Pages/ReadPage";

import "./App.css";
import "./Bible_reading.css";
import "./search.css";

import Search from "./Pages/SearchPage";
import Register from "./Pages/authPages/RegisterPage";
import Login from "./Pages/authPages/LoginPage";
import { AuthProvider } from "./contexts/AuthContext";
import Dashboard from "./Pages/DashboardPages/Dashboard";
import PrivateRoute from "./Components/PrivateRoute";
import NotFound from "./Pages/NotFound";
import UpdateProfile from "./Pages/authPages/UpdateProfile";
import ForgotPassword from "./Pages/authPages/ForgotPassword";
import ProfilePage from "./Pages/DashboardPages/ProfilePage";
import NewPost from "./Pages/DashboardPages/NewPost";
// import SearchBar from "./Pages/SearchTest";
import AddInfoProfile from "./Pages/DashboardPages/AddInfoProfile";
import PrayerPage from "./Pages/PrayerPage";
import RosaryPage from "./Pages/DashboardPages/RosaryPage";

function App() {
  return (
    <div className="main_app_container">
      <Router>
        <AuthProvider>
          <Routes>
            {/* <Route exact path="/dashboard" element={<PrivateRoute />}>
              <Route exact path="/dashboard" element={<Dashboard />} />
            </Route>
            <Route exact path="/update-profile" element={<PrivateRoute />}>
              <Route exact path="/update-profile" element={<UpdateProfile />} />
            </Route>
            <Route path="/profile-page/:uid" element={<PrivateRoute />}>
              <Route path="/profile-page/:uid" element={<ProfilePage />} />
            </Route>
            <Route path="/new-post" element={<PrivateRoute />}>
              <Route path="/new-post" element={<NewPost />} />
            </Route>
            <Route path="/prayers" element={<PrivateRoute />}>
              <Route path="/prayers" element={<PrayerPage />} />
            </Route>
            <Route path="/prayers/rosary" element={<PrivateRoute />}>
              <Route path="/prayers/rosary" element={<RosaryPage />} />
            </Route> */}
            {/* <Route
              path="/profile-page/add-profile-info"
              element={<PrivateRoute />}
            >
              <Route
                path="/profile-page/add-profile-info"
                element={<AddInfoProfile />}
              />
            </Route> */}
            <Route path="/" element={<Home />} />
            <Route path="/prayers" element={<PrayerPage />} />
            <Route path="/prayers/rosary" element={<RosaryPage />} />

            <Route path="/bible" element={<Read />} />
            {/* <Route path="/search" element={<Search />} /> */}
            <Route path="/register" element={<Register />} />
            {/* <Route path="/login" element={<Login />} /> */}
            {/* <Route path="/forgot-password" element={<ForgotPassword />} /> */}
            <Route path="*" element={<NotFound />} />
            {/* <Route exact path="/profile-page" element={<Login />} /> */}
          </Routes>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
