import React from "react";
import NavBottom from "../Components/Dashboard/NavBottom";
import PrayersComp from "../Components/Dashboard/PrayersComp";
import { Nav } from "../Components/Nav";

const PrayerPage = () => {
  return (
    <>
      <Nav />
      <PrayersComp />
      {/* <NavBottom /> */}
    </>
  );
};

export default PrayerPage;
